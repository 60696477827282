<template>
  <div class="online">
    <div class="container" :style="{paddingTop: '4.4rem'}">
      <!-- 底图 -->
      <img
        class="fixed-top"
        v-if="!soninfo.count || !is_play || sectiontype != 1"
        :src="info.images"
      />
      <!-- 视频 sectiontype == 1 -->
      <video-player
        class="fixed-top vjs-custom-skin"
        v-if="soninfo.count && is_play && sectiontype == 1 && !videoVisible"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
      ></video-player>

      <div v-html="videoContent" v-if="videoVisible"></div>
      <!-- 图文 sectiontype == 2 -->

      <!-- 音频 sectiontype == 3 -->
      <aplayer
        v-if="sectiontype == 3 && reFresh"
        :music="videoUpload.music"
        :class="navBarFixed == true ? 'music-fixed' :''"
        ref="player"
      ></aplayer>

      <!-- 外链 sectiontype == 4 -->

      <div class="title">
        <div>
          <h4>{{ info.bookname }}</h4>
          <h6>{{ info.teacher_name }}</h6>
        </div>
        <div class="poster" @click="poster" v-show="vipshare == 1">
          <i class="jym-icon-test15"></i>
          <br />海报
        </div>
      </div>
      <div class="text">
        <div class="gold-num fl" v-show="parseInt(info.price) > 0">￥{{ info.price }}</div>
        <div class="fr">
          <span class="gold-num">{{ info.num }}</span>人已购
          <em v-if="soninfo.count">
            <i class="jym-shuxian"></i>
            {{ soninfo.count }}节
          </em>
        </div>
      </div>

      <mt-navbar v-model="selected" :class="navBarFixed ? 'navBarWrap' :''">
        <mt-tab-item id="2">听课地点</mt-tab-item>
        <mt-tab-item id="1">课程介绍</mt-tab-item>
        <mt-tab-item id="3">在线学习</mt-tab-item>
      </mt-navbar>

      <div v-show="navBarFixed" style="height: 0.95rem"></div>

      <mt-tab-container v-model="selected" class="switch">
        <mt-tab-container-item id="1">
          <div class="desc">
            <p v-if="info.descript" v-html="info.descript"></p>
            <div v-else class="emptyData" v-show="initLoading">
              <img src="@assets/images/emptyData.png" />
              <p>暂无课程介绍</p>
            </div>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="2">
          <div v-if="addressList.length > 0">
            <div class="address" v-for="(item, index) in addressList" :key="index">
              <div class="info">
                <p>{{ item.alias }}</p>
                <p>{{ item.starttime.substring(0, item.starttime.length - 3) }}</p>
              </div>
              <div class="apply">
                <button
                  @click="apply(item.id)"
                  :class="item.num > 0 ? 'abled' : 'disabled'"
                  :disabled="item.num > 0 ? false : true"
                >申请</button>
                <span>{{ item.num > 0 ? `剩余${item.num}人`: '截止报名' }}</span>
              </div>
            </div>
          </div>
          <div v-else class="desc">
            <div class="emptyData" v-show="initLoading">
              <img src="@assets/images/emptyData.png" />
              <p>暂无听课地点</p>
            </div>
          </div>
        </mt-tab-container-item>
        <mt-tab-container-item id="3">
          <div v-if="soninfo.count > 0">
            <div v-for="(item, i) in soninfo.data" :key="i">
              <div
                class="list"
                @click="videoPlay(item.id, item.is_free, item.test_time, item.sectiontype, item.playtime, i)"
              >
                <div class="left">
                  <i class="jym-bofang1" v-show="item.sectiontype == 1"></i>
                  <i class="jym-tuwen1" v-show="item.sectiontype == 2"></i>
                  <i class="jym-yinyue" v-show="item.sectiontype == 3"></i>
                  <i class="jym-bofang1" v-show="item.sectiontype == 4"></i>
                  <span class="ell" :class="{'gold-color': index == i}">{{ item.title }}</span>
                  <em v-if="item.is_free == 1 && item.test_time > 0">试听</em>
                </div>
                <div class="right" v-if="is_play && item.sectiontype == 1">
                  <span v-if="item.playtime >= 120" class="gold-color">已看{{ item.playtime_m }}分钟</span>
                  <span v-else-if="item.playtime > 0" class="gold-color">已看{{ item.playtime }}秒</span>
                  <span v-else>未学习</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="emptyData" v-show="initLoading">
            <img src="@assets/images/emptyData.png" />
            <p>暂无课程</p>
          </div>
        </mt-tab-container-item>
      </mt-tab-container>
    </div>

    <!-- 分享 -->
    <ShareInfo v-on:setShareInfoStatus="setShareInfoStatus" :shareInfoStatus="shareInfoStatus"></ShareInfo>

    <!-- 图文 -->
    <mt-popup v-model="contentVisible" position="center" :style="{height: popupHeight}">
      <i @click="contentVisible = false" class="jym-icon-test44"></i>
      <div class="content" v-html="content" :style="{height: popupHeight}"></div>
    </mt-popup>

    <!-- 客服 -->
    <mt-popup v-model="serviceVisible" lockScroll="true" @touchmove.native.stop.prevent>
      <i @click="serviceVisible = false" class="jym-close dialog-close"></i>
      <service :pay_qrcode="pay_qrcode"></service>
    </mt-popup>

    <!-- 海报 -->
    <mt-popup v-model="posterVisible" position="center" :style="{height: auto}">
      <i @click="posterVisible = false" class="jym-icon-test44"></i>
      <poster :posterImg="posterImg"></poster>
    </mt-popup>

    <!-- 底部支付 -->
    <Footer-Pay
      @pay="pay"
      @collect="collect"
      @service="service"
      @poster="poster"
      :is_play="is_play"
      :is_collect="is_collect"
      :vipshare="vipshare"
    ></Footer-Pay>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { isWeixin } from "@utils/index";
import { wechatEvevt } from "@libs/wechat";
import FooterPay from "@components/FooterPay";
import Poster from "@views/Lesson/poster";
import Service from "@views/Lesson/service";
import { mapGetters } from "vuex";
import ShareInfo from "@components/ShareInfo";
import { lessonDetail, lessonRecord, lessonBill } from "@api/public";
import { getCollectAdd, getCollectDel, getUserInfo } from "@api/user";
const _isWechat = isWeixin();

export default {
  name: "offline-detail",
  components: {
    FooterPay,
    Poster,
    Service,
    ShareInfo,
  },
  data() {
    return {
      id: "",
      kf_id: "",
      sid: "",
      uid: "",
      index: "",
      is_play: "",
      is_free: "",
      is_collect: "",
      durations: "",
      testTime: "",
      playtime: "",
      popupHeight: "",
      sectiontype: "",
      pay_qrcode: "",
      vipshare: "",
      selected: "2",
      messageApply: "",
      positionPrice: "",
      posterImg: [],
      share: "",
      paused: true,
      reFresh: true,
      videoVisible: false,
      contentVisible: false,
      serviceVisible: false,
      posterVisible: false,
      shareInfoStatus: false,
      weixinStatus: false,
      navBarFixed: false,
      initLoading: false,
      videoContent: "",
      content: "",
      timer: "",
      info: {},
      soninfo: {},
      addressList: {},
      playerOptions: {
        // 视频播放配置
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      videoUpload: {
        // 音频播放配置
        progress: false,
        progressPercent: 0,
        successPercent: 0,
        music: {
          title: "",
          author: " ",
          url: "",
          pic: "",
          lrc: "",
        },
      },
    };
  },
  computed: mapGetters(["isLogin"]),
  created() {
    this.id = this.$route.params.id;
    this.kf_id = this.$route.query.kf_id;
    this.popupHeight = window.innerHeight / 50 - 4 + "rem";
    this.getList();
    window.location.href = "/testh5/pages/subcontract/onlineClass/detail?id=" + this.id
  },
  mounted() {
    // 视频定时器
    this.timer = setInterval(this.putLearningObj, 20000);
    // 事件监听滚动条
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    watchScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  当滚动超过 80 时，实现吸顶效果
      if (scrollTop > 80) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },

    stopVideo() {
      this.$refs.videoPlayer.player.pause();
      MessageBox.confirm(
        "试听时间已结束，继续学习请购买本课程！"
      ).then(() => {});
    },

    // 播放状态且当前进度时间大于播放进度时间,定时请求保存进度
    putLearningObj() {
      let that = this;
      if (!that.paused && parseInt(that.durations) > parseInt(that.playtime)) {
        let params = {
          id: that.id,
          sid: that.sid,
          playtime: that.durations,
        };

        lessonRecord(params).then();
      }
    },

    // 视频播放
    onPlayerPlay() {
      this.paused = false;
    },

    // 视频暂停
    onPlayerPause() {
      this.paused = true;
    },

    // 视频播放结束
    onPlayerEnded() {
      this.paused = false;
    },

    // 获取视频播放时间
    onPlayerTimeupdate(player) {
      this.durations = parseInt(player.cache_.currentTime);
      // 试听时间结束视频禁止播放
      if (!this.is_play && this.is_free == 1 && this.test_time > 0) {
        if (this.testTime >= this.durations) {
          this.stopVideo();
        }
      }
    },

    // 列表点击事件
    videoPlay(sid, is_free, testTime, sectiontype, playtime, index) {
      let that = this;
      that.$set(that, "sid", sid);
      that.$set(that, "is_free", is_free);
      that.$set(that, "testTime", testTime);
      that.$set(that, "sectiontype", sectiontype);
      that.$set(that, "playtime", playtime);
      that.$set(that, "index", index || 0);
      if (that.is_play) {
        let info = that.soninfo.data[index || 0];

        // 资源切换
        switch (sectiontype) {
          case 1:
            if (info.savetype == 2 || info.savetype == 4) {
              that.videoContent = info.videourl;
              that.videoVisible = true;
            } else {
              that.sid = info.id;
              that.is_free = info.is_free;
              that.testTime = info.testTime;
              that.playerOptions.sources[0].src = info.videourl;
              that.videoVisible = false;
            }
            break;
          case 2:
            that.content = info.content;
            that.contentVisible = true;
            break;
          case 3:
            that.$set(that.videoUpload.music, "title", info.title);
            that.$set(that.videoUpload.music, "pic", info.pic);
            that.$set(that.videoUpload.music, "url", info.videourl);
            break;
          case 4:
            window.open(info.videourl, "_self");
            break;
        }

        // 强制刷新组件
        that.reFresh = false;
        that.$nextTick(() => {
          that.reFresh = true;
        });
      } else {
        if (that.info.is_study == 1) {
          that.$dialog.error("请先购买课程！");
        } else {
          that.$dialog.error("暂未开放！");
        }
      }
    },

    // 获取列表数据
    getList() {
      let that = this;
      lessonDetail(that.id, that.kf_id).then((res) => {
        let data = res.data;
        that.$set(that, "is_play", data.is_play);
        that.$set(that, "info", data.info);
        that.$set(that, "is_collect", data.is_collect);
        that.$set(that, "addressList", data.offline);
        that.$set(that, "soninfo", data.soninfo);
        that.$set(that, "pay_qrcode", data.info.pay_qrcode);
        that.$set(that, "vipshare", data.info.vipshare);
        that.$set(that, "positionPrice", data.positionPrice);
        that.$set(that, "share", JSON.parse(data.info.share));
        that.initLoading = true;
        that.messageApply = `<i class="jym-zhuyi"></i><h2>上课说明：</h2><p>因课程现场席位有限，为了有效避免学员报名课程后实际上并没有来到现场学习而造成的学习资格浪费，
        导致想参加现场学习的人无法参加学习。也为了让聚亿美工作人员提前做好会前准备工作，给学员提供更好的服务和学习体验：</p>
         <p>
          从2019年1月22日起，所有学员若要参加线下课程，课程现场学习，
          需要交纳
          <span class="gold-num">${that.positionPrice}</span>元的座位保证金，在到达当期课程现场签到成功后退回
          <span class="gold-num">${that.positionPrice}</span>元。
        </p>
         <p>
          我们支持签到日10天以前改签不扣款，累计免费改签2次。（例如：线下课程若3月18日开课，3月17日签到，
          免费改签时间截止至3月6日24:00，3月7日00:00起不可操作改签，
          <span class="gold-num">${that.positionPrice}</span>元保证金费用不可退，不可下次使用）。
        </p>
        <p class="bottom">
          聚亿美教育咨询有限公司
          <br />2019年1月22日
        </p>`;

        // 默认加载第一条数据
        if (data.soninfo.data.length > 0) {
          let info = data.soninfo.data[0];
          that.firstParams = [
            info.sid,
            info.is_free,
            info.test_time,
            info.sectiontype,
            0,
          ];
          that.sectiontype = info.sectiontype;
          switch (that.sectiontype) {
            case 1:
              if (info.savetype == 2 || info.savetype == 4) {
                that.videoContent = info.videourl;
                that.videoVisible = true;
              } else {
                that.sid = info.id;
                that.is_free = info.is_free;
                that.testTime = info.testTime;
                that.playtime = info.playtime;
                that.playerOptions.poster = data.info.images;
                that.playerOptions.sources[0].src = info.videourl;
                that.videoVisible = false;
              }
              break;
            case 2:
              break;
            case 3:
              that.$set(that.videoUpload.music, "title", info.title);
              that.$set(that.videoUpload.music, "pic", info.pic);
              that.$set(that.videoUpload.music, "url", info.videourl);
              break;
            case 4:
              // window.open(info.videourl, "_self");
              break;
          }
        }
        that.isView = true;
        that.setOpenShare();
        that.$dialog.loading.close();
      });
    },

    // 分享
    setOpenShare: function () {
      var data = this.info;
      var href = location.href;
      var share = this.share;

      if (_isWechat) {
        if (this.isLogin) {
          getUserInfo().then((res) => {
            if(res.data.kf_id) {
               href =
              href.indexOf("?") === -1
                ? href + "?kf_id=" + res.data.kf_id
                : href + "&kf_id=" + res.data.kf_id;
            }else{
               href =
              href.indexOf("?") === -1
                ? href + "?spread=" + res.data.uid
                : href + "&spread=" + res.data.uid;
            }
            this.uid = res.data.uid;
            var configAppMessage = {
              desc: share.descript ? share.descript : data.intro,
              title: share.title ? share.title : data.bookname,
              link: href,
              imgUrl: share.images ? share.images : data.images,
            };
            wechatEvevt(
              [
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
              ],
              configAppMessage
            )
              .then((res) => {
                console.log(res);
              })
              .catch((res) => {
                if (res.is_ready) {
                  res.wx.updateAppMessageShareData(configAppMessage);
                  res.wx.onMenuShareAppMessage(configAppMessage);
                  res.wx.updateTimelineShareData(configAppMessage);
                  res.wx.onMenuShareTimeline(configAppMessage);
                }
              });
          });
        } else {
          var configAppMessage = {
            desc: share.descript ? share.descript : data.intro,
            title: share.title ? share.title : data.bookname,
            link: href,
            imgUrl: share.images ? share.images : data.images
          };
          wechatEvevt(
            [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
            ],
            configAppMessage
          )
            .then((res) => {
              console.log(res);
            })
            .catch((res) => {
              if (res.is_ready) {
                res.wx.updateAppMessageShareData(configAppMessage);
                res.wx.onMenuShareAppMessage(configAppMessage);
                res.wx.updateTimelineShareData(configAppMessage);
                res.wx.onMenuShareTimeline(configAppMessage);
              }
            });
        }
      }
    },

    collect() {
      let that = this,
        id = that.id;

      if (that.is_collect) {
        getCollectDel(id, "lesson").then(() => {
          that.$set(that, "is_collect", false);
        });
      } else {
        getCollectAdd(id, "lesson").then(() => {
          that.$set(that, "is_collect", true);
        });
      }
    },

    // 支付
    pay() {
      if (this.addressList.length > 0) {
        this.selected = "2";
        // this.$router.push(`/lesson/apply/${this.id}`);
      } else {
        this.$dialog.error("暂无听课地点，无法购买！");
      }
    },

    // 客服
    service() {
      this.serviceVisible = true;
    },

    // 海报
    poster() {
      let that = this;

      if (that.vipshare === 1) {
        let params = {
          banner: that.info.images,
          qrcode: window.location.href + "?spread=" + that.uid, // 当前页面地址+登录用户id
          lesson_id: that.id,
        };

        lessonBill(params)
          .then((res) => {
            that.$set(that, "posterImg", res.data);
            that.posterVisible = true;
          })
          .catch((err) => {
            that.$dialog.error(err.msg);
          });
      } else {
        this.setShareInfoStatus()
      }
    },

    // 申请
    apply(sid) {
      MessageBox({
        title: "",
        message: this.messageApply,
        showCancelButton: true,
      }).then((status) => {
        if (status === "confirm") {
          this.$router.push({
            path: `/lesson/applyDetail`,
            query: { id: this.id, sid: sid, kf_id: this.kf_id },
          });
        } else {
          return;
        }
      });
    },

    // 分享
    setShareInfoStatus: function () {
      this.shareInfoStatus = !this.shareInfoStatus;
    },

  },
  destroyed() {
    // 如果定时器在运行则关闭
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.online {
  padding-bottom: 1rem;
  .container {
    background: white;
    position: relative;
    .fixed-top {
      height: 4.4rem;
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    img {
      width: 100%;
      height: auto;
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 0.1rem 0.3rem;
      h4 {
        font-size: 0.28rem;
      }
      h6 {
        font-size: 0.26rem;
        font-weight: 500;
        line-height: 0.6rem;
        color: #b0b0b0;
      }
      .poster {
        i {
          font-size: 0.36rem;
        }
        text-align: center;
        font-size: 0.2rem;
      }
    }
    .text {
      padding: 0 0.3rem;
      overflow: hidden;
      i {
        font-size: 0.28rem;
      }
    }
    .switch {
      .list {
        height: 0.88rem;
        line-height: 0.88rem;
        border-top: solid #eaeaea 0.02rem;
        font-size: 0.26rem;
        padding: 0 0.3rem;
        display: flex;
        justify-content: space-between;
        .left {
          max-width: 5.5rem;
          display: flex;
          justify-content: space-between;
          i {
            font-size: 0.42rem;
            margin-right: 0.1rem;
          }
          span {
            max-width: 4.5rem;
          }
          em {
            font-size: 0.18rem;
            border: 0.02rem solid red;
            border-radius: 1rem;
            color: red;
            width: 0.6rem;
            height: 0.32rem;
            line-height: 0.32rem;
            text-align: center;
            margin: 0.28rem 0 0 0.1rem;
          }
        }
        .right {
          color: #a9a9a9;
          font-size: 0.24rem;
        }
      }
      .desc {
        line-height: 0.44rem;
        font-size: 0.26rem;
        padding: 0.2rem 0.3rem;
        border-top: solid #eaeaea 0.02rem;
      }
    }
    .bar {
      font-size: 0.28rem;
      height: 0.8rem;
      line-height: 0.8rem;
      overflow: hidden;
      color: white;
      text-align: center;
      letter-spacing: 0.1rem;
    }
    .desc {
      padding: 0.2rem 0.3rem;
      line-height: 0.44rem;
      font-size: 0.28rem;
    }
    .address {
      width: 6.9rem;
      margin: 0 0.3rem;
      padding: 0.3rem 0;
      border-bottom: 0.02rem dashed #ddd;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .info {
        width: 5rem;
        p {
          margin-bottom: 0.06rem;
          line-height: 0.36rem;
          font-size: 0.26rem;
        }
      }
      .apply {
        width: 1.5rem;
        font-size: 0.24rem;
        text-align: center;
        button {
          display: block;
          margin: 0 auto 0.06rem;
          padding: 0.1rem 0.24rem;
          border-radius: 0.04rem;
          letter-spacing: 0.04rem;
        }
        .abled {
          color: white;
          background: #6b7bb0;
        }
        .disabled {
          color: #666;
          background: #ddd;
        }
      }
    }
  }
  .mint-popup {
    width: 5.5rem;
    background: transparent;
  }
  .navBarWrap {
    position: fixed;
    top: 4.4rem;
    z-index: 999;
    width: 100%;
    height: .95rem;
  }
  .music-fixed {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    margin: 0;
  }
}
.mask {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  z-index: 888 !important;
}
</style>
