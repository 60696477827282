<template>
  <div class="dialog" @click="close">
    <div v-show="posterImg.length > 0">
      <div v-if="posterImg.length == 1">
        <img v-lazy="posterImg[0]">
      </div>
      <div v-else>
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in posterImg" :key="index">
            <img v-lazy="item"/>
          </swiper-slide>
          <div class="swiper-pagination paginationBanner" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";
export default {
  props: ["posterImg"],
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".paginationBanner",
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true
      }
    };
  },
  methods: {
    close() {
      this.$parent.$parent.posterVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.dialog {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
  }
}
</style>
