<template>
  <div class="dialog">  
    <div class="service">
      <div class="head-img">
        <img src="@assets/images/logo1.png" />
      </div>
      <h4>聚亿美客服</h4>
      <img class="qr-code" :src="pay_qrcode">
      <p>长按识别二维码</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pay_qrcode']
}
</script>

<style lang="scss" scoped>
.dialog {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: white;
  .service {
    padding-bottom: 0.5rem;
    .head-img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      position: absolute;
      left: 2.15rem;
      top: -0.6rem;
      background: white;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .qr-code {
      display: block;
      width: 80%;
      margin: .3rem auto;
    }
    h4 {
      text-align: center;
      font-weight: 500;
      margin-top: .8rem;
      line-height: .6rem;
      font-size: .28rem;
    }
    p {
      color: #A9A9A9;
      font-size: .26rem;
      text-align: center;
    }
  }
}
</style>
