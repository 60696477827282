<template>
  <div class="pay">
    <div class="icon">
      <div v-if="is_pass == 1 && !is_play && isPassword" class="password-btn" @click="passwordEvent">
        密码验证
      </div>
      <div v-else>
        <ul>
          <li @click="collectEvent">
            <i :class="is_collect ? 'jym-aixin1 orange' : 'jym-aixin'"></i>收藏
          </li>
          <li @click="serviceEvent">
            <i class="jym-rexiandianhua"></i>客服
          </li>
          <li @click="posterEvent">
            <i class="jym-fenxiang1"></i>
            {{ vipshare == 1 ? '海报' : '分享' }}
          </li>
        </ul>
      </div>
    </div>
    <button @click="pay">{{ payText }}</button>
  </div>
</template>
<script>
export default {
  name: "Footer-Pay",
  props: ["is_play", "is_collect", "is_pass", "vipshare"],
  data: function() {
    return {
      payText: "",
      isPassword: true
    };
  },
  watch: {
    is_play() {
      if (this.$route.path.indexOf("offline") != "-1") {
        this.payText = "立即报名";
      } else {
        if (this.is_play) {
          this.payText = "立即学习";
        } else {
          this.payText = "立即购买";
        }
      }
    }
  },
  created() {
    this.isPassword = sessionStorage.getItem("verifyArray") ? false : true
  },
  methods: {
    pay() {
      this.$emit("pay");
    },
    collectEvent() {
      this.$emit("collect");
    },
    serviceEvent() {
      this.$emit("service");
    },
    posterEvent() {
      this.$emit("poster");
    },
    passwordEvent() {
      this.$emit("passwordEvent")
    }
  }
};
</script>

<style lang="scss" scoped>
.pay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.88rem;
  background: white;
  display: flex;
  justify-content: space-between;
  .icon {
    width: 3.75rem;
    height: 0.88rem;
    .password-btn {
      text-align: center;
      line-height: .88rem;
      font-weight: 700;
      font-size: .28rem;
      color: white;
      background: linear-gradient(to right, #f35447 0, #ff8e3c 100%);
    }
    ul {
      width: 100%;
      height: 0.88rem;
      display: flex;
      justify-content: start;
      li {
        width: 1rem;
        padding-top: 0.1rem;
        text-align: center;
        font-size: 0.18rem;
        color: #656565;
        i {
          display: block;
          font-size: 0.4rem;
          height: 0.45rem;
          line-height: 0.45rem;
          text-align: center;
        }
      }
    }
  }
  button {
    width: 3.75rem;
    font-size: 0.32rem;
    color: white;
    line-height: 0.88rem;
    text-align: center;
    background-image: linear-gradient(to right, #141518, #2f3237);
  }
}
</style>
